<template>
  <div class="list-manage my-device need-manage">
    <section class="list-manage-table">
      <div class="table-title">
        <span class="title">需求管理</span  ><span class="device-map"   ><i class="f-icon icon-location-orange"></i>设备地图</span >
      </div>
      <div class="tabs-bar">
        <div
          class="tabs-item"
          :class="{ selected: index == tabsCurrent }"
          v-for="(item, index) in tabsList"
          :key="index"
          @click="tabsChange(index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="thead" >
        <span class="th">设备</span>
        <span class="th">状态</span>
        <span class="th">操作</span>
      </div>
      <template>
        <div class="tbody" v-loading="loading" >
          <template v-if="this.needMage.length !== 0">
            <div class="list-item" v-for="index in needMage" :key="index.id">
          <div class="list-item-header">
            <span class="order-number"></span
            ><span class="status">{{index.audit_state==1?'待审核':index.audit_state==2?'审核通过':'审核失败'}}</span>
          </div>
          <div class="list-item-main">
            <div class="td device">
              <el-image :src="index.seekrent_img.url" style="width: 60px;height: 60px; margin-right: 11px;">
                <div slot="error" class="image-slot">
                  <img src="~@/assets/images/temp/temp_03.png" class="device-pic" alt="">
                </div>
              </el-image>
              {{
                index.name
              }}
            </div>
            <div class="td">{{index.status==1?'正常':'下架'}}</div>
            <div class="td"><span class="btn" @click="goRevise(index)">修改</span>
              <span
                  class="btn"
                  @click="goEnd(index)"
                  style="background-color: #fa0041; color: #fff;margin-top:10px; border: 0"
                  >删除</span>
            </div>

          </div>
        </div>
          </template>
          <div v-else-if="needMage.length === 0 && !loading"  style="text-align: center">暂无数据</div>
       
      </div>
      </template>
      
    </section>
    <div class="pagination">
      <el-pagination
        background
        layout="total,prev, pager, next,jumper"
        :page-size="page.pageSize"
        :current-page="page.pageNumber"
        :total="total.totalRow"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getNeed,delNeed} from "../../api/myorder";
import kits from '@/kits/index.js'
export default {
  data() {
    return {
      loading:true,
      tabsCurrent: 0,
      tabsList: ["待审核", "已审核", "审核失败"],
      needMage: [],
      page: {
        pageNumber: 1,
        pageSize: 10,
      },
      total: {
        totalPage: null,
        totalRow: null,
      },
      
    };
  },
  created() {
    this.getneed();
  },
  methods: {
    goRevise(index) {
      this.$router.push({ path: `/reviseNeed`,query:{id:index.device_seekrent_id}});
    },
    handleCurrentChange(val) {
      this.page.pageNumber = val;
      this.getlist()
      window.scrollTo(0,0);
    },
    getlist(){
      if (this.tabsCurrent == 0) {
        this.tabChange(this.tabsCurrent);
        this.loading = false
        window.scrollTo(0, 0);
      } else if (this.tabsCurrent == 1) {
        this.tabChange(this.tabsCurrent);
        this.loading = false
        window.scrollTo(0, 0);
      } else if (this.tabsCurrent == 2) {
        this.tabChange(this.tabsCurrent);
        this.loading = false
        window.scrollTo(0, 0);
      } else {
        this.getneed();
      }
    },
    async tabChange(index){
      this.loading = true
        let res = await getNeed({
          inputs: { audit_state: index+1 },
          pagination: this.page,
        });
        this.needMage = res.data.list;
        this.total.totalPage = res.data.totalPage;
        this.total.totalRow = res.data.totalRow;
        console.log(this.needMage);
        for(let i =0;i<this.needMage.length;i++){
        let seekrent_imgs = JSON.parse(this.needMage[i].seekrent_img)
      this.needMage[i].seekrent_img = ({url:kits.img.url(seekrent_imgs[0])})
      }
    },
    tabsChange(index) {
      this.page.pageNumber = 1
      this.tabsCurrent = index;
      if (this.tabsCurrent == 0) {
        this.tabChange(this.tabsCurrent);
      } else if (this.tabsCurrent == 1) {
        this.tabChange(this.tabsCurrent);
      } else if (this.tabsCurrent == 2) {
        this.tabChange(this.tabsCurrent);
      }
      else if (this.tabsCurrent == 3) {
        this.tabChange(this.tabsCurrent);
      }
      this.loading = false
    },
    async getneed() {
      let res = await getNeed({inputs:{audit_state: 1,pagination:this.page}});
      this.needMage = res.data.list;
      console.log(res);
      this.total.totalPage = res.data.totalPage
      this.total.totalRow = res.data.totalRow
      for(let i =0;i<this.needMage.length;i++){
        let seekrent_imgs = JSON.parse(this.needMage[i].seekrent_img)
      this.needMage[i].seekrent_img = ({url:kits.img.url(seekrent_imgs[0])})
      }
      this.loading = false
    },
    async goEnd(index) {
      const confirm = await this.$confirm(
        "是否删除需求?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((error) => error);
      if (confirm !== "confirm") {
        return this.$message.info("您已取消删除");
      }
    this.delneed(index)
    },
    delneed(index){
      delNeed({device_seekrent_id: index.device_seekrent_id}).then((res) => {
        if (res.status === 200) {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getlist()
        } else {
          this.$message({
            message: "删除失败",
            type: "error",
          });
        }
      });
    }
  },
};
</script>

<style scoped>
</style>
